import React from 'react'
import logo from "../../assets/images/mainlogo.png";
import { Link } from 'react-router-dom';

const SmallHeader = () => {
    return (
        <>
          <div id='small_header' className='small_header'>
            <nav className="navbar bg-white fixed-top">
                <div className="container">
                    <Link className="navbar-brand fw-bold" to='/'>
                        <img src={logo} className="img-fluid main_logo" alt='logo' />
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
                        {/* <span className="navbar-toggler-icon"></span> */}
                        <span className="fa fa-bars"></span>
                    </button>
                    <div className="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                            <Link className="navbar-brand fw-bold" to='/'>
                        <img src={logo} className="img-fluid main_logo" alt='logo' />
                    </Link>
                                </h5>
                            <button type="button" className="btn-close fa fa-xmark" data-bs-dismiss="offcanvas" aria-label="Close">
                                {/* <i  className=""></i> */}
                                </button>
                        </div>
                        <div className="offcanvas-body">
                            <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                            <li className="nav-item">
                                <Link to="/about" className="nav-link" >About</Link>
                            </li>
                               
                                <li className="nav-item dropdown">
                                    <Link className="nav-link dropdown-toggle" data-bs-auto-close="outside" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Services
                                    </Link>
                                    <ul className="dropdown-menu">
                                    <li>
                                        <Link className="dropdown-item dropdown-toggle" href="#" data-bs-toggle="dropdown">Design</Link>
                                        <ul className='dropdown-menu border-0 ps-2' >
                                               <li> <Link className='dropdown-item' to="/our-services-ui-ux">UI/UX Design</Link></li>
                                                <li><Link className='dropdown-item' to="/our-services-photoshop"> Photoshop / AI</Link></li>
                                                <li><Link className='dropdown-item' to="/our-services-figma">Figma / Adobe XD</Link></li>
                                                <li><Link className='dropdown-item' to="/our-services-html-css"> HTML5/CSS3/BOOTSTRAP </Link></li>
                                                <li><Link className='dropdown-item' to="/our-services-javascript">VUE JS</Link></li>
                                               <li> <Link className='dropdown-item' to="/our-services-react">React JS</Link></li>
                                            
                                        </ul>
                                     </li>
                                    <li>
                                        <Link className="dropdown-item dropdown-toggle" href="#" data-bs-toggle="dropdown">Development</Link>
                                        <ul className='dropdown-menu border-0 ps-2' >
                                               <li> <Link className='dropdown-item' to="/our-services-core-php">Core PHP</Link></li>
                                                <li> <Link className='dropdown-item' to="/our-services-laravel">Laravel (9, 10)</Link></li>
                                                <li><Link className='dropdown-item' to="/our-services-yii-development"> Ci, Yii Framework</Link></li>
                                                <li><Link className='dropdown-item' to="/our-services-wordpress">Wordpress Development</Link></li>
                                                <li><Link className='dropdown-item' to="/our-services-ecommerce-website">WooCommerce, eCommerce</Link></li>
                                               <li> <Link className='dropdown-item' to="/our-services-shopify">Shopify</Link></li>
                                               <li> <Link className='dropdown-item' to="/our-services-asp">ASP .NET</Link></li>
                                            
                                        </ul>
                                     </li>
                                    <li>
                                        <Link className="dropdown-item dropdown-toggle" href="#" data-bs-toggle="dropdown">Digital Marketing</Link>
                                        <ul className='dropdown-menu border-0 ps-2' >
                                               <li> <Link className='dropdown-item' to="/our-services-seo-smm-smo">SEO / SMM / SMO</Link></li>
                                                <li><Link className='dropdown-item' to="/our-services-ppc">Pay Per Click</Link></li>
                                                <li> <Link className='dropdown-item' to="/our-services-market-research">Market Research</Link></li>
                                                <li><Link className='dropdown-item' to="/our-services-branding">Branding</Link></li>
                                                <li><Link className='dropdown-item' to="/our-services-content-pr-rm">PR/ RM  </Link></li>
                                               <li><Link className='dropdown-item' to="/our-services-content-writing">Content Writing</Link></li>
                                            
                                        </ul>
                                     </li>
                                    <li>
                                        <Link className="dropdown-item dropdown-toggle" href="#" data-bs-toggle="dropdown">Mobile Development</Link>
                                        <ul className='dropdown-menu border-0 ps-2' >
                                               <li><Link className='dropdown-item' to="/our-services-android">Android App</Link></li>
                                                <li><Link className='dropdown-item' to="/our-services-ios-app">IOS App</Link></li>
                                                <li><Link className='dropdown-item' to="/our-services-hybrid">Hybrid App</Link></li>
                                                <li><Link className='dropdown-item' to="/our-services-flutter">Flutter</Link></li>
                                                <li><Link className='dropdown-item' to="/our-services-react-native">React Native</Link></li>
                                               <li><Link className='dropdown-item' to="/our-services-kotlin">Kotlin</Link></li>
                                               <li><Link className='dropdown-item' to="/our-services-ionic">Ionic</Link></li>
                                               <li><Link className='dropdown-item' to="/our-services-swift">Swift</Link></li>
                                            
                                        </ul>
                                     </li>
                                    {/* <li><Link className="dropdown-item" href="#">Development</Link></li>
                                    <li><Link className="dropdown-item" href="#">Digital marketing</Link></li>
                                    <li><Link className="dropdown-item" href="#">Mobile Development</Link></li> */}
                                    </ul>
                                </li>

                                <li className="nav-item">
                                <Link to="/our-works" className="nav-link" >Portfolio</Link>
                                 </li>
                                <li className="nav-item">
                                <Link to="/blogs" className="nav-link" >Blogs</Link>
                                 </li>
                                <li className="nav-item">
                                <Link to="/careers" className="nav-link" >Career</Link>
                                 </li>
                                <li className="nav-item">
                                <Link to="/gallery" className="nav-link" >Gallery</Link>
                                 </li>
                                {/* <li className="nav-item">
                                <Link to="/gallery" className="nav-link" >Schedule Meeting</Link>
                                 </li> */}
                            </ul>
                            <Link to="/contact" className='ms-2'> <button type="button" className="btn btn-contact">Contact Us</button></Link>         
                        </div>
                    </div>
                </div>
            </nav>
            </div>  
        </>
    )
}

export default SmallHeader