import { React, useState } from 'react'
import "./services.css";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { Link } from 'react-router-dom';

import timeicon from "../../assets/images/time-icon.png";
import teamicon from "../../assets/images/team-icon.png";
import focusicon from "../../assets/images/focus-icon.png";
import trusticon from "../../assets/images/trust-icon.png";

import Form from "./Form";
import ClientReview from '../../Components/Reviews/ClientReview';

import Client2 from '../Main-page/Client2';
import Client3 from '../Main-page/Client3';
import { Icon } from '@iconify/react';
import data from '../OurWorks/data';
import data1 from '../OurWorks/data1';
import { Helmet } from 'react-helmet';


const Kotlin = () => {
    const [showMore, setShowMore] = useState(false);

    const [sites, setSites] = useState(data);
    const [apps, setApps] = useState(data1);


    const toggleMoreText = () => {
        setShowMore(!showMore);
    };

    return (

        <>
         <Helmet>
            <meta charSet="utf-8" />
            <title>Expert Kotlin App Development Services | Grintech Web Agency</title>
            <meta name="description" content="Advanced Kotlin Expertise: Leveraging Kotlin's modern features, including null safety, extension functions, and coroutines, to build robust and reliable Android app" />
            <link rel='canonical' href='/our-services-kotlin' />
         </Helmet>


            <Header />

            <section className="dev-passion mt-4 mb-1">
                <div className="container d-none d-lg-block py-3">
                    <div className="row">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="passion">
                                <h1 className='fw-bold'>Kotlin App Development Company</h1>
                                <div className=''>
                                    <p>Kotlin: Modern App Solutions by Kotlin app developer, Grintech offers the best Android app development using Kotlin. Our experienced developers build the best Kotlin service and apps high-performance, scalable apps with clean, efficient code and the latest features, tailored to meet your business needs and deliver a seamless user experience.</p>

                                    <h2>Here’s what Grintech provides in Kotlin App Development services:</h2>
                                    
                                    <ul className="p-0">
                                    <li className='mb-2'><b>1. Custom Kotlin Development:</b> Building tailored Android apps using Kotlin, focusing on your specific business requirements and user needs.</li>
                                    <li className='mb-2'><b>2. High-Performance Apps:</b> Developing efficient and high-performance applications with Kotlin’s modern features and capabilities.</li>
                                    <li className='mb-2'><b>3. Scalable Solutions:</b>Creating scalable apps that can grow with your business and adapt to future needs and updates.</li>
                                    <li className='mb-2'><b>4. Clean Code Architecture:</b> Using Kotlin’s concise syntax and safety features to write clean, maintainable, and efficient code.</li>
                                    <li className='mb-2'><b>5. Advanced Feature Integration:</b> Incorporating the latest Android features, including complex functionalities, real-time data processing, and interactive elements.</li>
                                   
                                    </ul>
                                </div>


                                {showMore && (
                                    <div className='moretext'>
                                       <ul className="p-0">
                                       <li className='mb-2'><b>6. User-Centric Design:</b> Designing intuitive and engaging user interfaces that enhance the overall user experience and ensure usability.</li>
                                        <li className='mb-2'><b>7. API Integration:</b> Connecting your app with third-party services and APIs for extended functionality and seamless data integration.</li>
                                        <li className='mb-2'><b>8. Testing & Quality Assurance:</b> Conducting comprehensive testing to ensure app stability, performance, and a bug-free experience across various devices.</li>
                                        <li className='mb-2'><b>9. Maintenance & Support:</b> Offering ongoing support and updates to keep your app running smoothly and compatible with new Android versions.</li>
                                        <li className='mb-2'><b>10. Consultation & Strategy:</b> Providing expert advice and strategic planning to align your app development with business goals and market trends.</li>
                                       </ul>
                                        <p>Grintech’s Kotlin App Development services focus on delivering reliable, high-quality, and efficient Android applications that cater to your unique needs and objectives. Grintech’s Kotlin App Development services offer robust, high-performance Android applications utilizing both Kotlin and Flutter. Our experienced team ensures that your app is scalable, user-friendly, and feature-rich, applying the latest techniques and insights, including knowledge from the Udemy Kotlin service. Whether you’re seeking Kotlin’s advanced capabilities or cross-platform solutions with Flutter, we deliver top-quality development that brings your vision to life efficiently.</p>
                                    </div>
                                )}


                                <Link className="moreless-button" onClick={toggleMoreText}>
                                    {showMore ? 'Read less... ' : 'Read more...'}
                                </Link>


                                <div className='d-flex mt-4'>
                                    <a href='tel: 833232-6622' className='btn-bt'> <i class="fa-solid fa-phone"></i> (+1) 833232-6622</a>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2 py-5">
                            <Form />
                        </div>
                    </div>
                </div>
                <div className="container d-block d-lg-none">
                    <div className="row">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="passion">

                                <div className=''>
                                    <h2>Here’s what Grintech provides in Kotlin App Development services:</h2>  
                                    <ul className="p-0">
                                    <li className='mb-2'><b>1. Custom Kotlin Development:</b> Building tailored Android apps using Kotlin, focusing on your specific business requirements and user needs.</li>
                                    <li className='mb-2'><b>2. High-Performance Apps:</b> Developing efficient and high-performance applications with Kotlin’s modern features and capabilities.</li>
                                    <li className='mb-2'><b>3. Scalable Solutions:</b>Creating scalable apps that can grow with your business and adapt to future needs and updates.</li>
                                    <li className='mb-2'><b>4. Clean Code Architecture:</b> Using Kotlin’s concise syntax and safety features to write clean, maintainable, and efficient code.</li>
                                    <li className='mb-2'><b>5. Advanced Feature Integration:</b> Incorporating the latest Android features, including complex functionalities, real-time data processing, and interactive elements.</li>
                                   
                                    </ul>
                                </div>


                                {showMore && (
                                    <div className='moretext'>
                                    <ul className="p-0">
                                       <li className='mb-2'><b>6. User-Centric Design:</b> Designing intuitive and engaging user interfaces that enhance the overall user experience and ensure usability.</li>
                                        <li className='mb-2'><b>7. API Integration:</b> Connecting your app with third-party services and APIs for extended functionality and seamless data integration.</li>
                                        <li className='mb-2'><b>8. Testing & Quality Assurance:</b> Conducting comprehensive testing to ensure app stability, performance, and a bug-free experience across various devices.</li>
                                        <li className='mb-2'><b>9. Maintenance & Support:</b> Offering ongoing support and updates to keep your app running smoothly and compatible with new Android versions.</li>
                                        <li className='mb-2'><b>10. Consultation & Strategy:</b> Providing expert advice and strategic planning to align your app development with business goals and market trends.</li>
                                       </ul>    
                                        <p>Grintech’s Kotlin App Development services focus on delivering reliable, high-quality, and efficient Android applications that cater to your unique needs and objectives. Grintech’s Kotlin App Development services offer robust, high-performance Android applications utilizing both Kotlin and Flutter. Our experienced team ensures that your app is scalable, user-friendly, and feature-rich, applying the latest techniques and insights, including knowledge from the Udemy Kotlin service. Whether you’re seeking Kotlin’s advanced capabilities or cross-platform solutions with Flutter, we deliver top-quality development that brings your vision to life efficiently.</p>
                                    </div>
                                )}


                                <Link className="moreless-button" onClick={toggleMoreText}>
                                    {showMore ? 'Read less... ' : 'Read more...'}
                                </Link>


                                <div className='d-flex mt-4'>
                                    <a href='tel: 833232-6622' className='btn-bt'> <i class="fa-solid fa-phone"></i> (+1) 833232-6622 </a>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2 py-5">
                            <h1 className='fw-bold'>Kotlin App Development Company</h1>
                            <p>Kotlin: Modern App Solutions by Kotlin app developer, Grintech offers the best Android app development using Kotlin. Our experienced developers build the best Kotlin service and apps high-performance, scalable apps with clean, efficient code and the latest features, tailored to meet your business needs and deliver a seamless user experience.</p>
                            <Form />
                        </div>
                    </div>
                </div>

            </section>
            <section className="why-us  pt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 pe-2 pe-md-5 blueback">
                            <div className="frame1">
                                <div className="inner-head pb-2">
                                    <h2>Why Hire Grintech Experts for Kotlin App  Development</h2>
                                </div>
                                <p>Hire dedicated Kotlin Developer on a Full-time, Hourly, or Fixed Project Cost basis & save up to 25% on development cost</p>
                            </div>

                            <div className="frame1 pt-5">
                                <div className="inner-head pb-2">
                                    <h2>Speed up your advancement with Grintech Experts:</h2>
                                </div>
                                <ul className="ps-3">
                                    <li>Fast hiring within 24 hours</li>
                                    <li>Simple & Transparent Pricing</li>
                                    <li>Fully Signed NDA & Code Security</li>
                                    <li>Regular Reporting & Transparent Communication</li>
                                    <li>Easy Exit Policy</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="why-frame">
                                <ul className="list-unstyled">
                                    <li>
                                        <div className="icon-area"><img className="img-fluid" src={timeicon} alt="flexible timing" /></div>
                                        <div className="icon-text">
                                            <h5>Flexible Timing</h5>
                                            <p>Grintech also offers flexible timing to accommodate your specific needs. Whether you require assistance during specific hours of the day or need support with managing projects across different time zones, Grintech can tailor their services to meet your scheduling preferences. </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon-area"><img className="img-fluid" src={teamicon} alt="dedicated team" /></div>
                                        <div className="icon-text">
                                            <h5>Dedicated Team</h5>
                                            <p>Grintech dedicated team plays a crucial role in project management, providing the expertise, collaboration, and focus needed to achieve project goals efficiently.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon-area"><img className="img-fluid" src={focusicon} alt="client focus" /></div>
                                        <div className="icon-text">
                                            <h5>Client Focus</h5>
                                            <p>Maintaining a client focus is paramount in any business or project. By prioritizing the needs, preferences, and satisfaction of clients, organizations can build strong relationships, foster loyalty, and drive sustainable growth.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon-area"><img className="img-fluid" src={trusticon} alt="trusted quality" /></div>
                                        <div className="icon-text">
                                            <h5>Trusted Quality</h5>
                                            <p>Trusted quality is a crucial factor for businesses aiming to establish a solid reputation and gain customer trust. It refers to consistently delivering products, services, and experiences that meet or exceed customer expectations, while also maintaining a high standard of reliability, durability, and performance. </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='portfolio'>
                <div className='container'> <h3>Our Portfolio</h3>
                    <h6> Web Development</h6>
                    <Client2 sites={sites} />
                    <Link to="/our-works" style={{ color: "#014072" }}>View all <span className="new-get"><Icon icon="bi:arrow-right" /></span></Link>

                    <div className='mt-5'>
                        <h6> Mobile Development</h6>
                        <Client3 apps={apps} />
                        <Link to="/our-works" style={{ color: "#014072" }}>View all <span className="new-get"><Icon icon="bi:arrow-right" /></span></Link>
                    </div>

                </div>

            </section>

            <ClientReview />
            <Footer />
        </>

    )
}

export default Kotlin
