import { React, useState } from 'react'
import "./services.css";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { Link } from 'react-router-dom';

import timeicon from "../../assets/images/time-icon.png";
import teamicon from "../../assets/images/team-icon.png";
import focusicon from "../../assets/images/focus-icon.png";
import trusticon from "../../assets/images/trust-icon.png";

import Form from "./Form";
import ClientReview from '../../Components/Reviews/ClientReview';

import Client2 from '../Main-page/Client2';
import Client3 from '../Main-page/Client3';
import { Icon } from '@iconify/react';
import data from '../OurWorks/data';
import data1 from '../OurWorks/data1';
import { Helmet } from 'react-helmet';


const Ios = () => {
    const [showMore, setShowMore] = useState(false);

    const [sites, setSites] = useState(data);
    const [apps, setApps] = useState(data1);

    const toggleMoreText = () => {
        setShowMore(!showMore);
    };

    return (

        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>iOS App Development Services in 2024- Grintech Web Agency</title>
            <meta name="description" content="Grintech provide best iOS App Development Service. Creating Innovative iPhone Apps for Your Smart Future. By understanding the unique requirements of each client." />
            <link rel='canonical' href='/our-services-ios-app' />
         </Helmet>


            <Header />

            <section className="dev-passion mt-4 mb-1">
                <div className="container d-none d-lg-block py-3">
                    <div className="row">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="passion">
                                <h1 className='fw-bold'>Full Stack iOS App Development Company</h1>
                                <div className=''>
                                    <p>With precision and creativity, Grintech can transform your app ideas into reality with its top-notch iOS app development services. We provide smooth API integration and free app testing with robust coding service for iPhone operating system app development.</p>

                                    <h2>User-centric iOS App Development service at Grintech Web agency :</h2>
                                    <ul className="p-0">
                                    <li className='mb-2'><b>1. Custom App Design:</b> Creating unique and visually appealing user interfaces (UI) and user experiences (UX) tailored to the client’s needs.</li>
                                    <li className='mb-2'><b>2. App Development:</b> Coding Apple apps using programming languages like Swift or Objective-C to build their features and functionality.</li>
                                    <li className='mb-2'><b>3. API Integration:</b> Connecting the free apps with external services, databases, and APIs to extend their capabilities and functionality.</li>
                                    <li className='mb-2'><b>4. Quality Assurance and Testing:</b> Performing rigorous free app testing to ensure the app is bug-free, performs well, and provides a seamless experience on various iOS devices.</li>
                                    <li className='mb-2'><b>5. Performance Optimization:</b> Enhancing the app’s speed, efficiency, and responsiveness to ensure a smooth user experience.</li>
                                    </ul>
                                </div>


                                {showMore && (
                                    <div className='moretext'>
                                    <ul className="p-0">
                                    <li className='mb-2'><b>6. App Store Submission:</b> Handling the process of submitting the app to the Apple App Store, including compliance with Apple’s guidelines and requirements.</li>
                                    <li className='mb-2'><b>7. Maintenance and Support:</b> Providing ongoing support and updates to fix bugs, add new features, and ensure compatibility with new iOS versions.</li>
                                    <li className='mb-2'><b>8. User Feedback Integration:</b> Incorporating user feedback to improve the app’s functionality and user experience.</li>
                                    </ul>
                                    <p>Grintech Provides Full Stack iOS App Development services that aim to deliver high-quality application development and user-friendly iOS applications that meet the client's goals and succeed in the competitive iOS marketplace. Grintech web agency providing ongoing updates, bug fixes, and technical support to keep the Apple apps up-to-date and functional after launch</p>
                                    </div>
                                )}


                                <Link className="moreless-button" onClick={toggleMoreText}>
                                    {showMore ? 'Read less... ' : 'Read more...'}
                                </Link>


                                <div className='d-flex mt-4'>
                                    <a href='tel: 833232-6622' className='btn-bt'> <i class="fa-solid fa-phone"></i> (+1) 833232-6622</a>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2 py-5">
                            <Form />
                        </div>
                    </div>
                </div>
                <div className="container d-block d-lg-none">
                    <div className="row">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="passion">

                                <div className=''>
                                    <h2>User-centric iOS App Development service at Grintech Web agency :</h2>
                                    <ul className="p-0">
                                    <li className='mb-2'><b>1. Custom App Design:</b> Creating unique and visually appealing user interfaces (UI) and user experiences (UX) tailored to the client’s needs.</li>
                                    <li className='mb-2'><b>2. App Development:</b> Coding Apple apps using programming languages like Swift or Objective-C to build their features and functionality.</li>
                                    <li className='mb-2'><b>3. API Integration:</b> Connecting the free apps with external services, databases, and APIs to extend their capabilities and functionality.</li>
                                    <li className='mb-2'><b>4. Quality Assurance and Testing:</b> Performing rigorous free app testing to ensure the app is bug-free, performs well, and provides a seamless experience on various iOS devices.</li>
                                    <li className='mb-2'><b>5. Performance Optimization:</b> Enhancing the app’s speed, efficiency, and responsiveness to ensure a smooth user experience.</li>
                                    </ul>
                                </div>


                                {showMore && (
                                    <div className='moretext'>
                                    <ul className="p-0">
                                    <li className='mb-2'><b>6. App Store Submission:</b> Handling the process of submitting the app to the Apple App Store, including compliance with Apple’s guidelines and requirements.</li>
                                    <li className='mb-2'><b>7. Maintenance and Support:</b> Providing ongoing support and updates to fix bugs, add new features, and ensure compatibility with new iOS versions.</li>
                                    <li className='mb-2'><b>8. User Feedback Integration:</b> Incorporating user feedback to improve the app’s functionality and user experience.</li>
                                    </ul>
                                    <p>Grintech Provides Full Stack iOS App Development services that aim to deliver high-quality application development and user-friendly iOS applications that meet the client's goals and succeed in the competitive iOS marketplace. Grintech web agency providing ongoing updates, bug fixes, and technical support to keep the Apple apps up-to-date and functional after launch</p>
                                    </div>
                                )}


                                <Link className="moreless-button" onClick={toggleMoreText}>
                                    {showMore ? 'Read less... ' : 'Read more...'}
                                </Link>


                                <div className='d-flex mt-4'>
                                    <a href='tel: 833232-6622' className='btn-bt'> <i class="fa-solid fa-phone"></i> (+1) 833232-6622 </a>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2 py-5">
                            <h1 className='fw-bold'>Full Stack iOS App Development Company</h1>
                            <p>With precision and creativity, Grintech can transform your app ideas into reality with its top-notch iOS app development services. We provide smooth API integration and free app testing with robust coding service for iPhone operating system app development.</p>
                            <Form />
                        </div>
                    </div>
                </div>

            </section>
            <section className="why-us  pt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 pe-2 pe-md-5 blueback">
                            <div className="frame1">
                                <div className="inner-head pb-2">
                                    <h2>Why Hire Grintech Experts for iOS App Development</h2>
                                </div>
                                <p>Hire dedicated iOS Developer on a Full-time, Hourly, or Fixed Project Cost basis & save up to 25% on development cost</p>
                            </div>

                            <div className="frame1 pt-5">
                                <div className="inner-head pb-2">
                                    <h2>Speed up your advancement with Grintech Experts:</h2>
                                </div>
                                <ul className="ps-3">
                                    <li>Fast hiring within 24 hours</li>
                                    <li>Simple & Transparent Pricing</li>
                                    <li>Fully Signed NDA & Code Security</li>
                                    <li>Regular Reporting & Transparent Communication</li>
                                    <li>Easy Exit Policy</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="why-frame">
                                <ul className="list-unstyled">
                                    <li>
                                        <div className="icon-area"><img className="img-fluid" src={timeicon} alt="flexible timing" /></div>
                                        <div className="icon-text">
                                            <h5>Flexible Timing</h5>
                                            <p>Grintech also offers flexible timing to accommodate your specific needs. Whether you require assistance during specific hours of the day or need support with managing projects across different time zones, Grintech can tailor their services to meet your scheduling preferences. </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon-area"><img className="img-fluid" src={teamicon} alt="dedicated team" /></div>
                                        <div className="icon-text">
                                            <h5>Dedicated Team</h5>
                                            <p>Grintech dedicated team plays a crucial role in project management, providing the expertise, collaboration, and focus needed to achieve project goals efficiently.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon-area"><img className="img-fluid" src={focusicon} alt="client focus" /></div>
                                        <div className="icon-text">
                                            <h5>Client Focus</h5>
                                            <p>Maintaining a client focus is paramount in any business or project. By prioritizing the needs, preferences, and satisfaction of clients, organizations can build strong relationships, foster loyalty, and drive sustainable growth.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon-area"><img className="img-fluid" src={trusticon} alt="trusted quality" /></div>
                                        <div className="icon-text">
                                            <h5>Trusted Quality</h5>
                                            <p>Trusted quality is a crucial factor for businesses aiming to establish a solid reputation and gain customer trust. It refers to consistently delivering products, services, and experiences that meet or exceed customer expectations, while also maintaining a high standard of reliability, durability, and performance. </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='portfolio'>
                <div className='container'> <h3>Our Portfolio</h3>
                    <h6> Web Development</h6>
                    <Client2 sites={sites} />
                    <Link to="/our-works" style={{ color: "#014072" }}>View all <span className="new-get"><Icon icon="bi:arrow-right" /></span></Link>

                    <div className='mt-5'>
                        <h6> Mobile Development</h6>
                        <Client3 apps={apps} />
                        <Link to="/our-works" style={{ color: "#014072" }}>View all <span className="new-get"><Icon icon="bi:arrow-right" /></span></Link>
                    </div>

                </div>

            </section>

            <ClientReview />
            <Footer />
        </>

    )
}

export default Ios